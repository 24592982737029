import Head from "next/head";

const NavigationSchema = ({ data }) => {
  // console.log("data", data);
  let names = data?.header_menu?.map((item) => item?.title) || ["Home"];
  let urls = data?.header_menu?.map(
    (item) => process.env.NEXT_PUBLIC_WEBSITE_URL + item?.canonical_url
  ) || ["/"];

  return (
    <Head>
      <script
        id="SiteNavigationSchema"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "SiteNavigationElement",
            name: names,
            url: urls,
          }),
        }}
      />
    </Head>
  );
};

export default NavigationSchema;
